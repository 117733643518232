/**
 * @generated SignedSource<<4f2780478f372ec46f2da39463b02983>>
 * @relayHash 7f4ab3734aec49086ac4a6694c7e1c3d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/491.0.0-2024-11-26T23:10:32.496Z/useCanShowLiveChatQuery

import { ConcreteRequest, Query } from 'relay-runtime';
export type useCanShowLiveChatQuery$variables = {
  fetchConfig: boolean;
  fetchSeller: boolean;
  sellerId: string;
};
export type useCanShowLiveChatQuery$data = {
  readonly viewer?: {
    readonly liveChatConfig: {
      readonly isAgentAvailable: boolean | null;
      readonly isChatEnabled: boolean | null;
    } | null;
    readonly seller?: {
      readonly isPendingUnpaid: boolean | null;
    } | null;
  };
};
export type useCanShowLiveChatQuery = {
  response: useCanShowLiveChatQuery$data;
  variables: useCanShowLiveChatQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchConfig"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchSeller"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sellerId"
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "touchPoint",
      "value": "Seller_OnBoarding"
    }
  ],
  "concreteType": "LiveChatConfigQuery",
  "kind": "LinkedField",
  "name": "liveChatConfig",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAgentAvailable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isChatEnabled",
      "storageKey": null
    }
  ],
  "storageKey": "liveChatConfig(touchPoint:\"Seller_OnBoarding\")"
},
v4 = [
  {
    "kind": "Variable",
    "name": "sellerId",
    "variableName": "sellerId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPendingUnpaid",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useCanShowLiveChatQuery",
    "selections": [
      {
        "condition": "fetchConfig",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "condition": "fetchSeller",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "Seller",
                    "kind": "LinkedField",
                    "name": "seller",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "useCanShowLiveChatQuery",
    "selections": [
      {
        "condition": "fetchConfig",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "condition": "fetchSeller",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "Seller",
                    "kind": "LinkedField",
                    "name": "seller",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ]
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ferrum/491.0.0-2024-11-26T23:10:32.496Z/useCanShowLiveChatQuery",
    "metadata": {},
    "name": "useCanShowLiveChatQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "42669d38d71e5e07b6c48d213e8a81a7";

export default node;
