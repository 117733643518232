/**
 * @generated SignedSource<<2d8eac8bb09bfae3c9e1169f2e568466>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DealerWrapper_viewer$data = {
  readonly navBar: {
    readonly buttons: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"Nav_buttons">;
    } | null> | null;
    readonly links: {
      readonly " $fragmentSpreads": FragmentRefs<"Nav_links">;
    } | null;
    readonly notifications?: {
      readonly recommendations: number | null;
      readonly " $fragmentSpreads": FragmentRefs<"Nav_notifications">;
    } | null;
  } | null;
  readonly seller?: {
    readonly " $fragmentSpreads": FragmentRefs<"Nav_seller" | "useIsSellerOnline_seller">;
  } | null;
  readonly " $fragmentType": "DealerWrapper_viewer";
};
export type DealerWrapper_viewer$key = {
  readonly " $data"?: DealerWrapper_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DealerWrapper_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "customBannerText"
    },
    {
      "kind": "RootArgument",
      "name": "dotComHost"
    },
    {
      "kind": "RootArgument",
      "name": "hasSellerPk"
    },
    {
      "kind": "RootArgument",
      "name": "hyperwalletLink"
    },
    {
      "kind": "RootArgument",
      "name": "renderNotifications"
    },
    {
      "kind": "RootArgument",
      "name": "sellerPk"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DealerWrapper_viewer",
  "selections": [
    {
      "condition": "hasSellerPk",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "sellerId",
              "variableName": "sellerPk"
            }
          ],
          "concreteType": "Seller",
          "kind": "LinkedField",
          "name": "seller",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Nav_seller"
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useIsSellerOnline_seller",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serviceId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PresenceResults",
                  "kind": "LinkedField",
                  "name": "presence",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "recentOnlineDate",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SellerPreferences",
                  "kind": "LinkedField",
                  "name": "sellerPreferences",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "showOnlineStatus",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": "navBar",
      "args": [
        {
          "kind": "Variable",
          "name": "customBannerText",
          "variableName": "customBannerText"
        },
        {
          "kind": "Variable",
          "name": "dotComHost",
          "variableName": "dotComHost"
        },
        {
          "kind": "Variable",
          "name": "hyperwalletLink",
          "variableName": "hyperwalletLink"
        },
        {
          "kind": "Variable",
          "name": "sellerPk",
          "variableName": "sellerPk"
        },
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "userId"
        }
      ],
      "concreteType": "DealerNavBar",
      "kind": "LinkedField",
      "name": "dealerNavBar",
      "plural": false,
      "selections": [
        {
          "condition": "renderNotifications",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "notifications",
              "args": null,
              "concreteType": "DealerNavBarBadges",
              "kind": "LinkedField",
              "name": "badges",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "recommendations",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Nav_notifications"
                }
              ],
              "storageKey": null
            }
          ]
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NavBarButton",
          "kind": "LinkedField",
          "name": "buttons",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Nav_buttons"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NavBarLinks",
          "kind": "LinkedField",
          "name": "links",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Nav_links"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "6003a02896e51d4493feafabfa9580ca";

export default node;
